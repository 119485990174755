<template>
  <div class="AppPiesChange">
    <app-subtitle
      @click="this.changeActivePies"
      class="AppPiesChange__subtitle subtitle--medium"
      :class="{ noActive: !this.getCheesecakePageData.isActive }"
      >{{ cheesecakeTitle['title_' + selectedLang] }}</app-subtitle
    >
    <app-subtitle
      class="AppPiesChange__subtitle subtitle--medium"
      :class="{ noActive: !this.getPiesPageData.isActive }"
      @click="this.changeActivePies"
      >{{ pieTitle['title_' + selectedLang] }}</app-subtitle
    >
  </div>
</template>

<script>
import AppSubtitle                from '../../../components/AppSubtitle';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppPiesChange',
  components: { AppSubtitle },
  data() {
    return {
      cheesecakeTitle: {
        title_ru: 'Чизкейки',
        title_ua: 'Чизкейки',
        title_en: 'Cheesecakes',
        title_pl: 'Serniki',
      },
      pieTitle: {
        title_ru: 'Пироги',
        title_ua: 'Пироги',
        title_en: 'Pies',
        title_pl: 'Ciasta',
      }
    };
  },

  methods: {
    // ...mapActions(['changeActivePies']),
    changeActivePies() {
    }
  },

  computed: {
    ...mapGetters(['getPiesPageData', 'getCheesecakePageData']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppPiesChange {
  display: none;

  @include media-d-m($screen-netbook) {
    margin-top: -95px;

    display: block;
    @include flex(center, center, row, nowrap);

    &__subtitle {
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 25px;
      }

      &.noActive {
        opacity: 0.5;
      }
    }
  }
}
</style>
