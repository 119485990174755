<template>
  <div class="AppPiesList" :class="{ reverse: isReverse }">
    <div class="AppPiesList__inner container" :class="{ reverse: isReverse }">
      <div class="AppPiesList__product-list" :class="{ reverse: isReverse }">
        <app-pies-card
          class="AppPiesList__item"
          :class="{ reverse: isReverse }"
          v-for="(card, key) in cards"
          :key="key"
          :id="card.pie_id"
          :title="card['title_' + selectedLang]"
          :title_ru="card['title_ru']"
          :title_ua="card['title_ua']"
          :title_en="card['title_en']"
          :title_pl="card['title_pl']"
          :weight="card.weight"
          :imageUrl="getImagePath(card)"
          :description="card['description_' + selectedLang]"
          :price="card.price"
          :isReverse="isReverse"
          :selected_count="card.selected_count"
        />
      </div>
      <div class="AppPiesList__product-title" :class="{ reverse: isReverse }">
        <app-title
          class="AppPiesList__title"
          :data-aos="isReverse ? 'fade-right' : 'fade-left'"
          >{{ productTitle }}</app-title
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppPiesCard from './AppPiesCard';
import AppTitle     from '../../../components/AppTitle';
import { BASE_URL } from "@/constants";
import {mapGetters} from "vuex";

export default {
  props: {
    isReverse: Boolean,
    cards: Array,
    productTitle: String,
    entity_id_key: ''
  },
  name: 'AppPiesList',
  components: {
    AppPiesCard,
    AppTitle,
  },

  data() {
    return {
      base_url: BASE_URL
    };
  },

  mounted() {
    console.log('TESTTTT_PIEEE_LOG!!!!!!!!!!!!!!_!_!_!', this.cards)
  },

  methods: {
    getImagePath(pieCardData) {
      if (pieCardData?.image?.length) {
        return BASE_URL + 'storage/public/item-image/' + pieCardData.image[0].title.toString()
      }

      return '';
    }
  },

  computed: {
  ...mapGetters('lang', ['selectedLang'])
  }
};
</script>

<style lang="scss">
.AppPiesList {
  margin: 50px 0;
  overflow: hidden;
  background: url('../../../../../assets/images/pies-list__chk__bg.png') right
    no-repeat;

  @include media-d-m($screen-netbook) {
    background: none;
    margin: 30px 0;
  }

  &.reverse {
    background: url('../../../../../assets/images/pies-list__pies__bg.png') left
      no-repeat;

    @include media-d-m($screen-netbook) {
      background: none;
    }
  }

  &__inner {
    @include flex(space-between, center, row, nowrap);
    height: 100%;
    position: relative;

    @include media-d-m($screen-netbook) {
      @include flex(center, center, column, wrap);
    }

    &.reverse {
      @include flex(space-between, center, row-reverse, nowrap);

      @include media-d-m($screen-netbook) {
        @include flex(center, center, column, wrap);
      }
    }
  }

  &__product-list {
    margin-right: 90px;
    max-width: 610px;
    @include flex(flex-start, center, row, wrap);
    @include media-d-m($screen-desktop-large) {
      margin-right: 0;
    }
    @include media-d-m($screen-netbook) {
      @include flex(center, center, column, wrap);
      margin-right: 0;
    }
    &.reverse {
      @include flex(flex-end, center, row, wrap);
      margin-right: 0px;
      margin-left: 90px;
      @include media-d-m($screen-desktop-large) {
        margin-left: 0;
      }
      @include media-d-m($screen-netbook) {
        @include flex(center, center, column, wrap);
        margin-left: 0;
      }
    }
  }

  &__product-title {
    @include flex(flex-end, center, row, nowrap);
    width: calc(50% - 90px);

    @include media-d-m($screen-netbook) {
      display: none;
    }
    &.reverse {
      @include flex(flex-start, center, row, nowrap);
      @include media-d-m($screen-netbook) {
        display: none;
      }
    }
  }

  &__item {
    margin-right: 20px;
    margin-bottom: 70px;

    @include media-d-m($screen-netbook) {
      margin-right: 0;
      margin-bottom: 60px;
    }

    &.reverse {
      margin-right: 0px;
      margin-left: 20px;

      @include media-d-m($screen-netbook) {
        margin-left: 0;
      }
    }
  }

  &__title {
    @include media-d-m($screen-desktop-large) {
      font-size: 55px;
    }
  }
}
</style>
