<template>
  <div class="AppPiesAbout">
    <div class="AppPiesAbout__inner container">
      <div class="AppPiesAbout__banner" data-aos="fade-right">
        <app-title
          :class="{ active: !!this['getIsDropMenuActive'] }"
          class="AppPiesAbout__title title--yellow"
          >{{ getSortedTexts[0]['text_' + this.selectedLang]}}</app-title
        >
        <app-subtitle
          :class="{ active: !!this['getIsDropMenuActive'] }"
          class="AppPiesAbout__subtitle"
          >{{ getSortedTexts[1]['text_' + this.selectedLang]}}</app-subtitle
        >
        <app-subtitle
          :class="{ active: !!this['getIsDropMenuActive'] }"
          class="AppPiesAbout__subtitle subtitle--medium"
          >{{ getSortedTexts[2]['text_' + this.selectedLang]}}</app-subtitle
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle       from '../../../components/AppTitle';
import AppSubtitle    from '../../../components/AppSubtitle';
import { mapGetters } from 'vuex';

export default {
  name: 'AppPiesAbout',
  components: {
    AppTitle,
    AppSubtitle,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getIsDropMenuActive', 'getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppPiesAbout {
  position: relative;
  z-index: 1;
  background: url('../../../../../assets/images/pies-about__bg.png') center no-repeat;
  background-size: cover;
  @include flex(center, center, row, nowrap);
  top: -115px;
  padding-top: 105px;

  @include media-d-m($screen-netbook) {
    padding-top: 105px;
  }

  &:after {
    content: '';
    position: absolute;
    @include position(0, 0, 0, 0);
    background: $body-color;
    opacity: 0.8;
    z-index: 2;
  }

  &__inner {
    height: 100vh;

    @include media-d-m($screen-netbook) {
      height: auto;
    }

    @include flex(flex-start, center, row, nowrap);

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, row, nowrap);
      padding-top: 30px;
      padding-bottom: 30px;
    }
    position: relative;
    z-index: 3;
  }

  &__title {
    max-width: 800px;
    margin-bottom: 20px;

    @include transition(opacity);

    &.active {
      opacity: 0;
    }

    @include media-d-m($screen-netbook) {
      margin-bottom: 15px;
    }
  }

  &__subtitle {
    &:not(:last-of-type) {
      margin-bottom: 60px;

      @include media-d-m($screen-netbook) {
        margin-bottom: 55px;
      }
    }

    &:not(:first-of-type) {
      max-width: 490px;
    }

    @include transition(opacity);

    &.active {
      opacity: 0;
    }

    @include media-d-m($screen-netbook) {
      margin-bottom: 0;
    }
  }
}
</style>
