<template>
  <div
      class="AppPiesCard"
      :key="id + weight"
      :data-aos="isReverse ? 'fade-left' : 'fade-right'"
  >
    <div class="AppPiesCard__title">
      <app-title class="title--small title--montserrat title--W500">{{
          title
        }}
      </app-title>
      <span class="AppPiesCard__weight">({{ weight }}г)</span>
    </div>
    <div class="AppPiesCard__img">
      <img
          :src="imageUrl"
          alt=""
          class="AppPiesCard__image"
      />
    </div>
    <div class="AppPiesCard__composition">
      {{ description }}
    </div>
    <div class="AppPiesCard__buy">
      <div class="AppPiesCard__price">
        <app-title
            class="AppPiesCard__price-title title--small title--montserrat title--W500"
        >{{ price }}
        </app-title
        >
        грн
      </div>
      <div class="AppPiesCard__amount">
        <span
            class="amount__minus"
            @click="minusAmount(); addPieInCart(id, amount, title, price)"
        >
          <icon-minus/>
        </span>

        <input
            type="number"
            name=""
            class="amount"
            v-model="amountGetter"
            @input="addPieInCart(id, amount, title, price, title_ru, title_ua, title_en, title_pl)"
        />
        <span
            class="amount__plus"
            @click="plusAmount(); addPieInCart(id, amount, title, price, title_ru, title_ua, title_en, title_pl)"
        >
          <icon-plus/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from '../../../components/AppTitle';
import {mapActions, mapGetters} from 'vuex';
import iconMinus from '../../../components/svg/iconMinus';
import iconPlus from '../../../components/svg/iconPlus';

export default {
  name: 'AppShoppingCard',
  props: {
    id: Number,
    title: String,
    title_ru: String,
    title_en: String,
    title_pl: String,
    title_ua: String,
    weight: Number,
    imageUrl: String,
    description: String,
    price: Number,
    isReverse: Boolean,
    selected_count: Number
  },
  components: {
    AppTitle,
    iconMinus,
    iconPlus,
  },
  data() {
    return {
      amount: 0,
    };
  },


  methods: {
    minusAmount: function () {
      if (this.amount > 0) {
        --this.amount;
        this.updatePieAmount({ id: this.id, amount: this.amount })
      }
    },
    plusAmount: function () {
      ++this.amount;
      this.updatePieAmount({ id: this.id, amount: this.amount })
    },

    ...mapActions(['addCartPie', 'updatePieAmount']),

    addPieInCart(id, amount, title, price, title_ru, title_ua, title_en, title_pl) {
      if (amount < 0) {
        this.amount = 0;
      }

      this.addCartPie({
        id: id,
        amount: amount,
        title: title,
        title_ru: title_ru,
        title_ua: title_ua,
        title_en: title_en,
        title_pl: title_pl,
        price: price,
      });

    },
  },
  computed: {
    ...mapGetters(['getCartPies', 'getPieCountById']),

    amountGetter: {
      get: function () {
        return this.getPieCountById(this.id)
      },

      set: function (newAmount) {
        this.updatePieAmount({ id: this.id, amount: newAmount })
      }
    }
  },
};
</script>
<style lang="scss">
.AppPiesCard {
  max-width: 285px;

  @include media-d-m($screen-netbook) {
    max-width: 100%;
  }

  &__title {
    @include flex(flex-start, flex-end, row, nowrap);
    margin-bottom: 8px;

    & h3 {
      @include media-d-m($screen-netbook) {
        font-size: 26px;
      }
    }
  }

  &__weight {
    @include text($h15, 400, $light-yellow, $f-montserrat);
    margin-left: 15px;
  }

  &__img {
    margin-bottom: 8px;
    overflow: hidden;
  }

  &__image {
    object-fit: cover;

    @include media-d-m($screen-netbook) {
      width: 100%;
      height: 100%;
    }
  }

  &__composition {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    margin-bottom: 15px;
  }

  &__buy {
    @include flex(space-between, center, row, nowrap);
  }

  &__price {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    @include flex(flex-start, flex-end, row, nowrap);
    font-weight: 300;
  }

  &__price-title {
    margin-right: 10px;
  }

  &__amount {
    @include flex(center, center, row, nowrap);
  }
}

.amount {
  @include flex(center, center, row, nowrap);
  @include size(40px, 35px);
  @include text($h20, 300, $light-yellow, $f-montserrat);
  text-align: center;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  @include transition(border-bottom);

  &:focus {
    border-bottom: 1px solid $light-yellow;
  }

  &__minus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    padding: 10px 0;

    & svg line {
      @include transition(stroke);
    }

    &:hover svg line {
      stroke: $yellow;
    }
  }

  &__plus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    padding: 10px 0;

    & svg line {
      @include transition(stroke);
    }

    &:hover svg line {
      stroke: $yellow;
    }
  }
}
</style>
