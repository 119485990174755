<template>
  <div class="AppPies">
    <AppPiesAbout />
    <AppPiesChange />
    <AppPiesList
        v-if="this.getCheesecakeList.length"
        :productTitle="cheesecakeTitle['title_' + selectedLang]"
      :isReverse="false"
      v-show="width <= 960 ? this.getCheesecakePageData.isActive : true"
      :cards="this.getCheesecakeList"
    />
    <AppPiesList
        v-if="this.getPiesList.length"
      :productTitle="pieTitle['title_' + selectedLang]"
      :isReverse="true"
      v-show="width <= 960 ? this.getPiesPageData.isActive : true"
      :cards="this.getPiesList"
    />
  </div>
</template>

<script>
import AppHeader      from '../../../components/AppHeader';
import AppPiesAbout   from '../components/AppPiesAbout.vue';
import AppPiesList    from '../components/AppPiesList';
import AppPiesChange  from '../components/AppPiesChange';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppPies',
  components: {
    AppHeader,
    AppPiesAbout,
    AppPiesList,
    AppPiesChange,
  },

  data() {
    return {
      width: null,
      cheesecakeTitle: {
        title_ru: 'Чизкейки',
        title_ua: 'Чизкейки',
        title_en: 'Cheesecakes',
        title_pl: 'Serniki',
      },
      pieTitle: {
        title_ru: 'Пироги',
        title_ua: 'Пироги',
        title_en: 'Pies',
        title_pl: 'Ciasta',
      }
    };
  },

  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
    this.initPies();
  },

  mounted() {

    setTimeout(() => {
      console.log('CHEEESECAKE_GETTER:::', this.getCheesecakeList);
      console.log('PIESSS_GETTER:::', this.getPiesList);
    }, 2000)
  },

  computed: {
    ...mapGetters(['getPiesPageData', 'getCheesecakePageData', 'getPiesList', 'getCheesecakeList']),
    ...mapGetters('lang', ['selectedLang'])
  },

  methods: {
    ...mapActions(['initPies']),
    updateWidth() {
      return (this.width = window.innerWidth);
    },
  },
};
</script>

<style lang="scss"></style>
